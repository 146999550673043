var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container information" },
    [
      _c(
        "ul",
        { ref: "slick", style: { height: _vm.height } },
        _vm._l(_vm.imgCount, function(index) {
          return _c("li", {
            key: index,
            staticClass: "ui-bg-img-contain",
            style: {
              backgroundImage:
                "url(" +
                _vm.$lib.imgUrl(
                  "img/information-" +
                    _vm.userData.category +
                    "/0" +
                    index +
                    ".png",
                  true
                ) +
                ")",
              backgroundPosotion: "center",
              width: _vm.width,
              height: _vm.height
            }
          })
        }),
        0
      ),
      _c("footer-box", {
        attrs: {
          submitText: _vm.currentNo < _vm.imgCount ? "다음" : "닫기",
          submitCb: function() {
            return _vm.next(_vm.currentNo)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }