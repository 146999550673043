<template>
  <div class="container information">
    <ul ref="slick" :style="{ height }">
      <li
        v-for="index in imgCount"
        :key="index"
        class="ui-bg-img-contain"
        :style="{
          backgroundImage: `url(${$lib.imgUrl(`img/information-${userData.category}/0${index}.png`, true)})`,
          backgroundPosotion: 'center',
          width,
          height,
        }"
      >
      </li>
    </ul>
    <footer-box
      :submitText="currentNo < imgCount ? '다음' : '닫기'"
      :submitCb="() => next(currentNo)"
    >
    </footer-box>
  </div>
</template>

<script>
import FooterBox from '@/components/common/FooterBox'
export default {
  components: {
    FooterBox,
  },
  data() {
    return {
      width: '100%',
      height: 'calc(100vh - 190px)',
      $slick: null,
      option: {
        arrows: false,
        dots: true,
        infinite: false,
      },
      currentNo: 1,
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    imgCount() {
      return this.userData.category === 'client' ? 7 : 6
    },
  },
  mounted() {
    this.$slick = $(this.$refs.slick)
    this.$slick.on('afterChange', (event, slick, currentSlide) => {
      this.currentNo = currentSlide + 1
    })
    this.$nextTick(() => {
      this.setSlick(this.option)
    })
  },
  methods: {
    setSlick(option = this.option) {
      this.$slick.slick(option)
    },
    next(currentNo) {
      if (currentNo < this.imgCount) {
        this.$slick.slick('slickNext')
        return
      }
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.container.information {
  padding-left: 0;
  padding-right: 0;

  .slick-slider {
    &.slick-dotted {
      margin: 0;
    }
    .slick-dots {
      bottom: 25px;
      .slick-active {
        button:before {
          color: #a059b6;
          opacity: 1;
        }
      }
    }
  }
}
</style>
